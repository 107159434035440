export default defineNuxtRouteMiddleware((to, from) => {
  const { hasStoredLoginCredentials, isSignedIn } = useUser()
  if (process.client && !isSignedIn() && !hasStoredLoginCredentials()) {
    // This soft cookie is used to ensure that you are redirected
    // on the server side if you are not logged in and are visiting a page with
    // the 'auth' middleware guard
    console.warn("No soft cookie found, redirecting to login", to, from)
    return navigateTo({ path: "/login" })
  }
})
